import React from "react"
import Seo from '../components/seo'

import Layout from '../components/layout'
import { PriceService } from '../components/priceService'
import { useStaticQuery, graphql } from 'gatsby'
import { SmallTitlePage } from '../components/common/smallTitlePage'

const BydgoszczCennik = () => {


  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
  `)
      return (
        <Layout bydgoszcz>
          <Seo
            title="Bydgoszcz cennik"
            description="Cennik zabiegów w BodyMed Group Bydgoszcz."
            url="https://bodymedgroup.pl/bydgoszcz-blog"
          />
          <SmallTitlePage 
            title='Cennik' 
          />
          <PriceService bydgoszcz/>
        </Layout>
    )}

export default BydgoszczCennik
